const getHosts = () => {
  switch (document.location.host) {
    case 'pay.genesispay.ru': {
      return {
        backend: 'backend.genesispay.ru',
        processing: 'api.genesispay.ru',
      };
    }
    case 'pay.invoice.su': {
      return {
        backend: 'backend.invoice.su',
        processing: 'secure.invoice.su',
        auth: 'auth.my.invoice.su',
        lk: 'backend.lk.invoice.su',
        payouts_processing: 'secure.invoice.su',
        lk_origin: 'https://lk.invoice.su',
      };
    }
    case 'm-pay.telepayonline.ru': {
      return {
        backend: 'backend.invoice.su',
        processing: 'secure.invoice.su',
      };
    }
    // TODO: del
    // case 'paytest.invoice.su': {
    //   return {
    //     backend: process.env.REACT_APP_BACKEND_BASEURL || 'backend.invoice.su',
    //     processing: process.env.REACT_APP_SECURE_BASEURL || 'secure.invoiced.ml',
    //   };
    // }
    case 'payment.processingcenter.ru': {
      return {
        backend: 'acs.processingcenter.ru',
        processing: 'api.processingcenter.ru',
      };
    }
    case 'payment.ecommerce-pay.ru': {
      return {
        backend: 'acs.ecommerce-pay.ru',
        processing: 'api.ecommerce-pay.ru',
      };
    }
    case 'payment.technomix-pay.ru': {
      return {
        backend: 'acs.technomix-pay.ru',
        processing: 'api.technomix-pay.ru',
      };
    }
    case 'payment.nextpayments.ru': {
      return {
        backend: 'acs.nextpayments.ru',
        processing: 'api.nextpayments.ru',
      };
    }
    // stage environment
    case 'pay.invoiced.ml': {
      return {
        backend: 'backend.invoice.su',
        processing: 'secure.invoiced.ml',
        auth: 'auth.my.invoice.su',
      };
    }
    // case 'paytest.invoice.su': {
    //   return {
    //     backend: 'proto2.invoice.su',
    //     processing: 'secure-test.invoiced.ml',
    //     auth: 'auth.backend.ofd-test.invoice.su',
    //   };
    // }
    case 'pay.genesispay.site': {
      return {
        backend: 'backend.genesispay.ru',
        processing: 'api.genesispay.site',
        auth: '',
      };
    }
    case 'payment.send2card.ru': {
      return {
        backend: 'acs.send2card.ru',
        processing: 'api.send2card.ru',
        auth: '',
      };
    }
    default: {
      return {
        backend: process.env.REACT_APP_BACKEND_BASEURL || 'proto2.invoice.su',
        // backend: process.env.REACT_APP_BACKEND_BASEURL || 'backend-dev.genesispay.tk',
        processing: process.env.REACT_APP_SECURE_BASEURL || 'api-test.invc.site',
        // auth: process.env.REACT_APP_AUTH_BASEURL || 'auth.my.invoice.su',
        auth: process.env.REACT_APP_AUTH_BASEURL || 'auth.backend.ofd-test.invoice.su',
        lk: process.env.REACT_APP_LK_BASEURL || 'lk.backend.ofd-test.invoice.su',
        payouts_processing: process.env.REACT_APP_PAYOUTS_PROCESSING_BASEURL || 'api-test.invc.site',
        lk_origin: process.env.REACT_APP_LK_ORIGIN || 'http://localhost:8080',
      };
    }
  }
};

const hosts = getHosts();
export const URL_BASE_BACKEND = `https://${hosts.backend}`;
export const URL_BASE_PROCESSING = `https://${hosts.processing}`;
export const URL_BASE_AUTH = `https://${hosts.auth}`;
export const URL_BASE_LK = `https://${hosts.lk}`;
export const URL_BASE_PAYOUTS_PROCESSING = `https://${hosts.payouts_processing}`;

export const URL_GET_CUSTOMER = `${URL_BASE_BACKEND}/api/customers.get`;
export const URL_GET_CUSTOMER_SESSION = `${URL_BASE_AUTH}/get_session`;
export const URL_ADD_CUSTOMER = `${URL_BASE_BACKEND}/api/customers.add`;
export const URL_GET_TERMINAL = `${URL_BASE_BACKEND}/api/customers.getCurTerminal`;
export const URL_GET_TRANSACTION = `${URL_BASE_BACKEND}/api/customers.getCurTransaction`;
export const URL_GET_FORM = `${URL_BASE_BACKEND}/api/telepay/getForm`;
export const URL_CUSTOMERS_PAY = `${URL_BASE_PROCESSING}/invoice/pay`;
export const URL_APPLE_PAY_VERIFY = `${URL_BASE_PROCESSING}/ApplePay/`;
export const URL_CUSTOMERS_PAY_TP_SERVICE = `${URL_BASE_PROCESSING}/invoice/payService`;
export const URL_CUSTOMERS_PAY_SBP = `${URL_BASE_BACKEND}/api/customers.SBPpay`;
export const URL_CUSTOMERS_PAY_SENDY = `${URL_BASE_PROCESSING}/sendy/tokenSendy`;
export const URL_CUSTOMERS_PAY_SAMSUNG = `${URL_BASE_PROCESSING}/SamsungPay/CreateTransaction`;
export const URL_CUSTOMERS_PAY_MONETA = `${URL_BASE_BACKEND}/api/customers.ExtraPay`;
export const URL_PAY_STATUS = `${URL_BASE_BACKEND}/api/customers.payStatus`;
export const GOOGLE_PAY_MERCHANTID = '12128297486880089068';
export const GOOGLE_PAY_GATEWAY = 'invoice';
export const YANDEX_METRICA_ID = 57274498;
export const GOOGLE_ANALYTICS_ID = 'UA-157439587-1';
export const URL_CUSTOMER_REG = `${URL_BASE_BACKEND}/api/customers.reg`;
export const URL_CUSTOMER_REG_END = `${URL_BASE_BACKEND}/api/customers.verify`;
export const URL_GET_CHECK = `${URL_BASE_BACKEND}/api/customers.getCheck`;
export const URL_GET_SBP_PARTICIPANTS = `${URL_BASE_BACKEND}/api/sbp/participants`;

export const URL_GET_DEBT = `${URL_BASE_BACKEND}/api/telepay/getDebt`;
export const URL_GET_PROVIDER_COMMISSION = `${URL_BASE_BACKEND}/api/telepay/getProviderCommission`;
export const URL_GET_REDIRECT_SERVICE = `${URL_BASE_BACKEND}/api/telepay/redirect_form`;
export const URL_GET_TS_IFNO = `${URL_BASE_BACKEND}/api/telepay/getInfo`;

export const URL_REFRESH_TOKEN = `${URL_BASE_LK}/auth/refresh`;
export const URL_GET_BUSINESS_CARDS = `${URL_BASE_LK}/payouts/cards/list`;
export const URL_GET_CARD_PAYOUT_EXTRA_DATA = `${URL_BASE_LK}/payouts/extra_data`;
export const URL_CREATE_BUSINESS_CARD = `${URL_BASE_PAYOUTS_PROCESSING}/banks/rosbank/b2c/internal/cards/bind`;
export const URL_CREATE_CARD_PAYOUT = `${URL_BASE_PAYOUTS_PROCESSING}/banks/rosbank/b2c/internal/payouts/create`;
export const URL_CREATE_CARD_PAYOUT_BANK = `${URL_BASE_PAYOUTS_PROCESSING}/banks/rosbank/b2c/payouts/create`;
export const URL_CHECK_CARD_PAYOUT_STATUS = `${URL_BASE_PAYOUTS_PROCESSING}/banks/rosbank/b2c/payouts/status`;

export const LK_ORIGIN = hosts.lk_origin;

export const URL_INVOICE_METRIKA = `${URL_BASE_BACKEND}/CreateVisit.php`;
export const cardsLogo = {
  MasterC: '/img/card/cc-mastercard.svg',
  MasterCard: '/img/card/cc-mastercard.svg',
  MIR: '/img/card/cc-mir.svg',
  VISA: '/img/card/cc-visa.svg',
};
